import "./App.scss";
import { Navigate, useLocation } from "react-router-dom";
import "./fcm/setup";
import React, { useEffect } from "react";
import { Button, notification } from "antd";
import { getMessaging, onMessage } from "firebase/messaging";
function App() {
    return <></>;
}

export default App;
